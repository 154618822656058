<template>
  <div class="popup_wrap" :style="`width:1200px; height: ${popupHeight}px; padding-bottom: 20px;`">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <form id="frm1">
        <div class="content w-100">
          <h1 class="page_title">{{ $t('msg.CSCT060G050.001') }}</h1><!-- DEM/DET Tariff -->
          <div class="content_box mt10">
            <!-- content_box -->
            <table class="tbl_search">
              <colgroup>
                <col width="80px">
                <col>
                <col width="100px">
                <col>
                <col width="100px">
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ $t('msg.CSCT060G050.002') }}</th><!-- 항구 -->
                  <td>
                    <e-auto-complete-place
                      id="podPod"
                      @change="changePod"
                      :ctr-cd="params.ctrCd"
                      :plc-cd="params.portCd"
                    />
                  </td>
                  <th>{{ $t('msg.CSCT060G050.003') }}</th><!-- 구분 -->
                  <td>
                    <select v-model="params.eiCatCd">
                      <option value="O">{{ $t('msg.CSCT060G050.004') }}</option><!-- 선적지 -->
                      <option value="I">{{ $t('msg.CSCT060G050.005') }}</option><!-- 양하지 -->
                      <option value="A">{{ $t('msg.CSCT060G050.011') }}</option><!-- ALL -->
                    </select>
                  </td>
                  <td class="text_right">
                    <a
                      class="button blue sh"
                      href="#"
                      @click.prevent="fninquiry()"
                    >{{ $t('msg.CSCT060G050.006') }}</a><!-- 검색 -->
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('msg.CSCT060G050.007') }}</th><!-- 항목 -->
                  <td>
                    <span class="ml10">
                      <input
                        :disabled="checkDemCnt === 0"
                        type="checkbox"
                        id="dt_chk1"
                        name="dt_chk"
                        value="01"
                        @change="changeDisplayColumn()"
                      >
                      <label for="dt_chk1">DEM</label>
                      <input
                        :disabled="checkDetCnt === 0"
                        type="checkbox"
                        id="dt_chk2"
                        name="dt_chk"
                        value="02"
                        @change="changeDisplayColumn()"
                      >
                      <label for="dt_chk2">DET</label>
                      <input
                        :disabled="checkDemDetCnt === 0"
                        type="checkbox"
                        id="dt_chk3"
                        name="dt_chk"
                        value="03"
                        @change="changeDisplayColumn()"
                      >
                      <label for="dt_chk3">DEM+DET</label>
                      <input
                        :disabled="checkOfcCnt === 0"
                        type="checkbox"
                        id="dt_chk4"
                        name="dt_chk"
                        value="04"
                        @change="changeDisplayColumn()"
                      >
                      <label for="dt_chk4">OFC</label>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('msg.CSCT060G050.008') }}</th><!-- Container Type -->
                  <td>
                    <span class="ml10">
                      <input
                        type="checkbox"
                        id="ct_chk1"
                        name="ct_chk"
                        value="GP"
                        checked
                        @change="changeDisplayRow()"
                      >
                      <label for="ct_chk1">GP</label>
                      <input
                        type="checkbox"
                        id="ct_chk2"
                        name="ct_chk"
                        value="HC"
                        checked
                        @change="changeDisplayRow()"
                      >
                      <label for="ct_chk2">HC</label>
                      <input
                        type="checkbox"
                        id="ct_chk3"
                        name="ct_chk"
                        value="RF"
                        checked
                        @change="changeDisplayRow()"
                      >
                      <label for="ct_chk3">RF</label>
                      <input
                        type="checkbox"
                        id="ct_chk4"
                        name="ct_chk"
                        value="TK"
                        checked
                        @change="changeDisplayRow()"
                      >
                      <label for="ct_chk4">TK</label>
                      <input
                        type="checkbox"
                        id="ct_chk5"
                        name="ct_chk"
                        value="OT"
                        checked
                        @change="changeDisplayRow()"
                      >
                      <label for="ct_chk5">OT</label>
                      <input
                        type="checkbox"
                        id="ct_chk6"
                        name="ct_chk"
                        value="FR"
                        checked
                        @change="changeDisplayRow()"
                      >
                      <label for="ct_chk6">FR</label>
                      <input
                        type="checkbox"
                        id="ct_chk7"
                        name="ct_chk"
                        value="SR"
                        checked
                        @change="changeDisplayRow()"
                      >
                      <label for="ct_chk7">SR</label>
                      <input
                        type="checkbox"
                        id="ct_chk8"
                        name="ct_chk"
                        value="DG"
                        checked
                        @change="changeDisplayRow()"
                      >
                      <label for="ct_chk8">DG</label>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div><!-- content_box // -->

          <div class="content_box mt10">
            <!-- content_box -->
            <div class="text_right mb8">
              <a
                v-print="'#frm1'"
                class="button sm"
                href="#"
              >{{ $t('msg.CSCT060G050.009') }}</a><!-- Print -->
            </div>
            <div id="tarrif-realgrid" :style="`width:100%; height:${gridHeight}px;`" />
            <!-- <p v-show="serviceLang === 'KOR'" class="txt_desc2">{{ $t('msg.CSCT060G050.012') }}</p> -->
            <!-- RF 컨테이너 모니터링 비용의 경우 3일까지 프리타임, 4일자부터 20', 40' 각각 KRW 25,000, KRW 50,000 발생합니다. -->
            <p v-show="serviceLang === 'KOR'" class="txt_desc2">{{ $t('msg.CSCT060G050.014') }}</p><!-- 한국발 : RF 컨테이너 모니터링(MOR) 비용의 경우 프리타임 제공일수는 3일이며, 4일차부터 20', 40' 각각 KRW 25,000, KRW 50,000 발생합니다. (부가세 10% 별도 및 반입일 포함) -->
            <p v-show="serviceLang === 'KOR'" class="txt_desc2">{{ $t('msg.CSCT060G050.015') }}</p><!-- 한국향 : RF 컨테이너 모니터링(MOR) 비용의 경우, 양하 후 플러그인 시점부터 48시간까지 선사 부담, 48시간 초과시 터미널과 직정산하셔야 합니다. -->
          </div><!-- content_box // -->
        </div><!-- content // -->
      </form>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import demDetTariff from '@/api/rest/trans/demDetTariff'
import print from 'vue-print-nb'

import { GridView, LocalDataProvider } from 'realgrid'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  // Type
  { fieldName: 'cd', dataType: 'text' },

  // Cgo
  { fieldName: 'cgoTypCd', dataType: 'text' },

  // [s] Freetime ====================================================
  // Freetime - DEM
  { fieldName: 'dem20Dds', dataType: 'text' },
  { fieldName: 'dem40Dds', dataType: 'text' },
  { fieldName: 'dem45Dds', dataType: 'text' },
  // Freetime - DET
  { fieldName: 'det20Dds', dataType: 'text' },
  { fieldName: 'det40Dds', dataType: 'text' },
  { fieldName: 'det45Dds', dataType: 'text' },
  // Freetime - DEM+DET
  { fieldName: 'demdet20Dds', dataType: 'text' },
  { fieldName: 'demdet40Dds', dataType: 'text' },
  { fieldName: 'demdet45Dds', dataType: 'text' },
  // Freetime - OFC
  { fieldName: 'ofc20Dds', dataType: 'text' },
  { fieldName: 'ofc40Dds', dataType: 'text' },
  { fieldName: 'ofc45Dds', dataType: 'text' },
  // [e] Freetime ====================================================

  // [s] Over Period Charge ==========================================
  // [s] Demurrage ===================================================
  { fieldName: 'demCurCd', dataType: 'text' },
  { fieldName: 'demRateApclDds', dataType: 'text' },
  { fieldName: 'dem20Rate', dataType: 'text' },
  { fieldName: 'dem40Rate', dataType: 'text' },
  { fieldName: 'dem45Rate', dataType: 'text' },
  { fieldName: 'demRateApclDds2', dataType: 'text' },
  { fieldName: 'dem20Rate2', dataType: 'text' },
  { fieldName: 'dem40Rate2', dataType: 'text' },
  { fieldName: 'dem45Rate2', dataType: 'text' },
  { fieldName: 'demRateApclDds3', dataType: 'text' },
  { fieldName: 'dem20Rate3', dataType: 'text' },
  { fieldName: 'dem40Rate3', dataType: 'text' },
  { fieldName: 'dem45Rate3', dataType: 'text' },
  { fieldName: 'demRateApclDds4', dataType: 'text' },
  { fieldName: 'dem20Rate4', dataType: 'text' },
  { fieldName: 'dem40Rate4', dataType: 'text' },
  { fieldName: 'dem45Rate4', dataType: 'text' },
  { fieldName: 'demRateApclDds5', dataType: 'text' },
  { fieldName: 'dem20Rate5', dataType: 'text' },
  { fieldName: 'dem40Rate5', dataType: 'text' },
  { fieldName: 'dem45Rate5', dataType: 'text' },
  // [e] Demurrage ===================================================

  // [s] Detention ===================================================
  { fieldName: 'detCurCd', dataType: 'text' },
  { fieldName: 'detRateApclDds', dataType: 'text' },
  { fieldName: 'det20Rate', dataType: 'text' },
  { fieldName: 'det40Rate', dataType: 'text' },
  { fieldName: 'det45Rate', dataType: 'text' },
  { fieldName: 'detRateApclDds2', dataType: 'text' },
  { fieldName: 'det20Rate2', dataType: 'text' },
  { fieldName: 'det40Rate2', dataType: 'text' },
  { fieldName: 'det45Rate2', dataType: 'text' },
  { fieldName: 'detRateApclDds3', dataType: 'text' },
  { fieldName: 'det20Rate3', dataType: 'text' },
  { fieldName: 'det40Rate3', dataType: 'text' },
  { fieldName: 'det45Rate3', dataType: 'text' },
  { fieldName: 'detRateApclDds4', dataType: 'text' },
  { fieldName: 'det20Rate4', dataType: 'text' },
  { fieldName: 'det40Rate4', dataType: 'text' },
  { fieldName: 'det45Rate4', dataType: 'text' },
  { fieldName: 'detRateApclDds5', dataType: 'text' },
  { fieldName: 'det20Rate5', dataType: 'text' },
  { fieldName: 'det40Rate5', dataType: 'text' },
  { fieldName: 'det45Rate5', dataType: 'text' },
  // [e] Detention ===================================================

  // [s] DEM+DET =====================================================
  { fieldName: 'demdetCurCd', dataType: 'text' },
  { fieldName: 'demdetRateApclDds', dataType: 'text' },
  { fieldName: 'demdet20Rate', dataType: 'text' },
  { fieldName: 'demdet40Rate', dataType: 'text' },
  { fieldName: 'demdet45Rate', dataType: 'text' },
  { fieldName: 'demdetRateApclDds2', dataType: 'text' },
  { fieldName: 'demdet20Rate2', dataType: 'text' },
  { fieldName: 'demdet40Rate2', dataType: 'text' },
  { fieldName: 'demdet45Rate2', dataType: 'text' },
  { fieldName: 'demdetRateApclDds3', dataType: 'text' },
  { fieldName: 'demdet20Rate3', dataType: 'text' },
  { fieldName: 'demdet40Rate3', dataType: 'text' },
  { fieldName: 'demdet45Rate3', dataType: 'text' },
  { fieldName: 'demdetRateApclDds4', dataType: 'text' },
  { fieldName: 'demdet20Rate4', dataType: 'text' },
  { fieldName: 'demdet40Rate4', dataType: 'text' },
  { fieldName: 'demdet45Rate4', dataType: 'text' },
  { fieldName: 'demdetRateApclDds5', dataType: 'text' },
  { fieldName: 'demdet20Rate5', dataType: 'text' },
  { fieldName: 'demdet40Rate5', dataType: 'text' },
  { fieldName: 'demdet45Rate5', dataType: 'text' },
  // [e] DEM+DET =====================================================

  // [s] OFC =========================================================
  { fieldName: 'ofcCurCd', dataType: 'text' },
  { fieldName: 'ofcRateApclDds', dataType: 'text' },
  { fieldName: 'ofc20Rate', dataType: 'text' },
  { fieldName: 'ofc40Rate', dataType: 'text' },
  { fieldName: 'ofc45Rate', dataType: 'text' },
  { fieldName: 'ofcRateApclDds2', dataType: 'text' },
  { fieldName: 'ofc20Rate2', dataType: 'text' },
  { fieldName: 'ofc40Rate2', dataType: 'text' },
  { fieldName: 'ofc45Rate2', dataType: 'text' },
  { fieldName: 'ofcRateApclDds3', dataType: 'text' },
  { fieldName: 'ofc20Rate3', dataType: 'text' },
  { fieldName: 'ofc40Rate3', dataType: 'text' },
  { fieldName: 'ofc45Rate3', dataType: 'text' },
  { fieldName: 'ofcRateApclDds4', dataType: 'text' },
  { fieldName: 'ofc20Rate4', dataType: 'text' },
  { fieldName: 'ofc40Rate4', dataType: 'text' },
  { fieldName: 'ofc45Rate4', dataType: 'text' },
  { fieldName: 'ofcRateApclDds5', dataType: 'text' },
  { fieldName: 'ofc20Rate5', dataType: 'text' },
  { fieldName: 'ofc40Rate5', dataType: 'text' },
  { fieldName: 'ofc45Rate5', dataType: 'text' }
  // [e] OFC =========================================================
  // [e] Over Period Charge ==========================================
]

const defColumns = [
  // Type
  {
    name: 'cd',
    fieldName: 'cd',
    type: 'text',
    width: '50',
    header: {
        text: 'Type'
    }
  },
  // Cgo
  {
    name: 'cgoTypCd',
    fieldName: 'cgoTypCd',
    type: 'text',
    width: '50',
    header: {
        text: 'Cgo'
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const value = cell.value
        let rtnStr = ''

        if (value === '01') rtnStr = 'HZ'
        if (value === '02') rtnStr = 'OOG'
        if (value === '03') rtnStr = 'ING'
        if (value === '04') rtnStr = 'RF'
        if (value === '05') rtnStr = 'NOR'
        if (value === '06') rtnStr = 'FB'

        return rtnStr
      }
    }
  },
  // Freetime - DEM
  {
    name: 'dem20Dds',
    fieldName: 'dem20Dds',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'dem40Dds',
    fieldName: 'dem40Dds',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'dem45Dds',
    fieldName: 'dem45Dds',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // Freetime - DET
  {
    name: 'det20Dds',
    fieldName: 'det20Dds',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'det40Dds',
    fieldName: 'det40Dds',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'det45Dds',
    fieldName: 'det45Dds',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // Freetime - DEM+DET
  {
    name: 'demdet20Dds',
    fieldName: 'demdet20Dds',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'demdet40Dds',
    fieldName: 'demdet40Dds',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'demdet45Dds',
    fieldName: 'demdet45Dds',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // Freetime - OFC
  {
    name: 'ofc20Dds',
    fieldName: 'ofc20Dds',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'ofc40Dds',
    fieldName: 'ofc40Dds',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'ofc45Dds',
    fieldName: 'ofc45Dds',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  }
]

const opcDemColumns = [
  // Over Period Charge - Demurrage
  {
    name: 'demCurCd',
    fieldName: 'demCurCd',
    type: 'text',
    width: '50',
    header: {
        text: 'CUR'
    }
  },
  // Demurrage_1
  {
    name: 'demRateApclDds',
    fieldName: 'demRateApclDds',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'dem20Rate',
    fieldName: 'dem20Rate',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'dem40Rate',
    fieldName: 'dem40Rate',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'dem45Rate',
    fieldName: 'dem45Rate',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // Demurrage_2
  {
    name: 'demRateApclDds2',
    fieldName: 'demRateApclDds2',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'dem20Rate2',
    fieldName: 'dem20Rate2',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'dem40Rate2',
    fieldName: 'dem40Rate2',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'dem45Rate2',
    fieldName: 'dem45Rate2',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // Demurrage_3
  {
    name: 'demRateApclDds3',
    fieldName: 'demRateApclDds3',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'dem20Rate3',
    fieldName: 'dem20Rate3',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'dem40Rate3',
    fieldName: 'dem40Rate3',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'dem45Rate3',
    fieldName: 'dem45Rate3',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // Demurrage_4
  {
    name: 'demRateApclDds4',
    fieldName: 'demRateApclDds4',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'dem20Rate4',
    fieldName: 'dem20Rate4',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'dem40Rate4',
    fieldName: 'dem40Rate4',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'dem45Rate4',
    fieldName: 'dem45Rate4',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // Demurrage_5
  {
    name: 'demRateApclDds5',
    fieldName: 'demRateApclDds5',
    type: 'text',
    width: '50',
    header: {
      text: 'Until'
    }
  },
  {
    name: 'dem20Rate5',
    fieldName: 'dem20Rate5',
    type: 'text',
    width: '50',
    header: {
      text: '20\''
    }
  },
  {
    name: 'dem40Rate5',
    fieldName: 'dem40Rate5',
    type: 'text',
    width: '50',
    header: {
      text: '40\''
    }
  },
  {
    name: 'dem45Rate5',
    fieldName: 'dem45Rate5',
    type: 'text',
    width: '50',
    header: {
      text: '45\''
    }
  }
]

const opcDetColumns = [
  // Over Period Charge - Detention
  {
    name: 'detCurCd',
    fieldName: 'detCurCd',
    type: 'text',
    width: '50',
    header: {
        text: 'CUR'
    }
  },
  // Detention_1
  {
    name: 'detRateApclDds',
    fieldName: 'detRateApclDds',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'det20Rate',
    fieldName: 'det20Rate',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'det40Rate',
    fieldName: 'det40Rate',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'det45Rate',
    fieldName: 'det45Rate',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // Detention_2
  {
    name: 'detRateApclDds2',
    fieldName: 'detRateApclDds2',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'det20Rate2',
    fieldName: 'det20Rate2',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'det40Rate2',
    fieldName: 'det40Rate2',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'det45Rate2',
    fieldName: 'det45Rate2',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // Detention_3
  {
    name: 'detRateApclDds3',
    fieldName: 'detRateApclDds3',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'det20Rate3',
    fieldName: 'det20Rate3',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'det40Rate3',
    fieldName: 'det40Rate3',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'det45Rate3',
    fieldName: 'det45Rate3',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // Detention_4
  {
    name: 'detRateApclDds4',
    fieldName: 'detRateApclDds4',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'det20Rate4',
    fieldName: 'det20Rate4',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'det40Rate4',
    fieldName: 'det40Rate4',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'det45Rate4',
    fieldName: 'det45Rate4',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // Detention_5
  {
    name: 'detRateApclDds5',
    fieldName: 'detRateApclDds5',
    type: 'text',
    width: '50',
    header: {
      text: 'Until'
    }
  },
  {
    name: 'det20Rate5',
    fieldName: 'det20Rate5',
    type: 'text',
    width: '50',
    header: {
      text: '20\''
    }
  },
  {
    name: 'det40Rate5',
    fieldName: 'det40Rate5',
    type: 'text',
    width: '50',
    header: {
      text: '40\''
    }
  },
  {
    name: 'det45Rate5',
    fieldName: 'det45Rate5',
    type: 'text',
    width: '50',
    header: {
      text: '45\''
    }
  }
]

const opcDemDetColumns = [
  // Over Period Charge - DEM+DET
  {
    name: 'demdetCurCd',
    fieldName: 'demdetCurCd',
    type: 'text',
    width: '50',
    header: {
        text: 'CUR'
    }
  },
  // DEM+DET_1
  {
    name: 'demdetRateApclDds',
    fieldName: 'demdetRateApclDds',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'demdet20Rate',
    fieldName: 'demdet20Rate',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'demdet40Rate',
    fieldName: 'demdet40Rate',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'demdet45Rate',
    fieldName: 'demdet45Rate',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // DEM+DET_2
  {
    name: 'demdetRateApclDds2',
    fieldName: 'demdetRateApclDds2',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'demdet20Rate2',
    fieldName: 'demdet20Rate2',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'demdet40Rate2',
    fieldName: 'demdet40Rate2',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'demdet45Rate2',
    fieldName: 'demdet45Rate2',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // DEM+DET_3
  {
    name: 'demdetRateApclDds3',
    fieldName: 'demdetRateApclDds3',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'demdet20Rate3',
    fieldName: 'demdet20Rate3',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'demdet40Rate3',
    fieldName: 'demdet40Rate3',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'demdet45Rate3',
    fieldName: 'demdet45Rate3',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // DEM+DET_4
  {
    name: 'demdetRateApclDds4',
    fieldName: 'demdetRateApclDds4',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'demdet20Rate4',
    fieldName: 'demdet20Rate4',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'demdet40Rate4',
    fieldName: 'demdet40Rate4',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'demdet45Rate4',
    fieldName: 'demdet45Rate4',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // DEM+DET_5
  {
    name: 'demdetRateApclDds5',
    fieldName: 'demdetRateApclDds5',
    type: 'text',
    width: '50',
    header: {
      text: 'Until'
    }
  },
  {
    name: 'demdet20Rate5',
    fieldName: 'demdet20Rate5',
    type: 'text',
    width: '50',
    header: {
      text: '20\''
    }
  },
  {
    name: 'demdet40Rate5',
    fieldName: 'demdet40Rate5',
    type: 'text',
    width: '50',
    header: {
      text: '40\''
    }
  },
  {
    name: 'demdet45Rate5',
    fieldName: 'demdet45Rate5',
    type: 'text',
    width: '50',
    header: {
      text: '45\''
    }
  }
]

const opcOfcColumns = [
  // Over Period Charge - OFC
  {
    name: 'ofcCurCd',
    fieldName: 'ofcCurCd',
    type: 'text',
    width: '50',
    header: {
        text: 'CUR'
    }
  },
  // OFC_1
  {
    name: 'ofcRateApclDds',
    fieldName: 'ofcRateApclDds',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'ofc20Rate',
    fieldName: 'ofc20Rate',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'ofc40Rate',
    fieldName: 'ofc40Rate',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'ofc45Rate',
    fieldName: 'ofc45Rate',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // OFC_2
  {
    name: 'ofcRateApclDds2',
    fieldName: 'ofcRateApclDds2',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'ofc20Rate2',
    fieldName: 'ofc20Rate2',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'ofc40Rate2',
    fieldName: 'ofc40Rate2',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'ofc45Rate2',
    fieldName: 'ofc45Rate2',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // OFC_3
  {
    name: 'ofcRateApclDds3',
    fieldName: 'ofcRateApclDds3',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'ofc20Rate3',
    fieldName: 'ofc20Rate3',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'ofc40Rate3',
    fieldName: 'ofc40Rate3',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'ofc45Rate3',
    fieldName: 'ofc45Rate3',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // OFC_4
  {
    name: 'ofcRateApclDds4',
    fieldName: 'ofcRateApclDds4',
    type: 'text',
    width: '50',
    header: {
        text: 'Until'
    }
  },
  {
    name: 'ofc20Rate4',
    fieldName: 'ofc20Rate4',
    type: 'text',
    width: '50',
    header: {
        text: '20\''
    }
  },
  {
    name: 'ofc40Rate4',
    fieldName: 'ofc40Rate4',
    type: 'text',
    width: '50',
    header: {
        text: '40\''
    }
  },
  {
    name: 'ofc45Rate4',
    fieldName: 'ofc45Rate4',
    type: 'text',
    width: '50',
    header: {
        text: '45\''
    }
  },
  // OFC_5
  {
    name: 'ofcRateApclDds5',
    fieldName: 'ofcRateApclDds5',
    type: 'text',
    width: '50',
    header: {
      text: 'Until'
    }
  },
  {
    name: 'ofc20Rate5',
    fieldName: 'ofc20Rate5',
    type: 'text',
    width: '50',
    header: {
      text: '20\''
    }
  },
  {
    name: 'ofc40Rate5',
    fieldName: 'ofc40Rate5',
    type: 'text',
    width: '50',
    header: {
      text: '40\''
    }
  },
  {
    name: 'ofc45Rate5',
    fieldName: 'ofc45Rate5',
    type: 'text',
    width: '50',
    header: {
      text: '45\''
    }
  }
]

// 상시 표기되야 하는 레이아웃
const defLayer = [
  'cd',
  'cgoTypCd',
  {
    name: 'Freetime',
    direction: 'horizontal',
    width: '600',
    items: [
      {
        name: 'DEM',
        direction: 'horizontal',
        width: '150',
        items: [
          { column: 'dem20Dds', width: '50' },
          { column: 'dem40Dds', width: '50' },
          { column: 'dem45Dds', width: '50' }
        ]
      },
      {
        name: 'DET',
        direction: 'horizontal',
        width: '150',
        items: [
          { column: 'det20Dds', width: '50' },
          { column: 'det40Dds', width: '50' },
          { column: 'det45Dds', width: '50' }
        ]
      },
      {
        name: 'DEM+DET',
        direction: 'horizontal',
        width: '150',
        items: [
          { column: 'demdet20Dds', width: '50' },
          { column: 'demdet40Dds', width: '50' },
          { column: 'demdet45Dds', width: '50' }
        ]
      },
      {
        name: 'OFC',
        direction: 'horizontal',
        width: '150',
        items: [
          { column: 'ofc20Dds', width: '50' },
          { column: 'ofc40Dds', width: '50' },
          { column: 'ofc45Dds', width: '50' }
        ]
      }
    ]
  }
]

const opcDemLayer = [
  {
    name: 'Demurrage',
    direction: 'horizontal',
    width: '850',
    items: [
      { column: 'demCurCd', width: '50' },
      { column: 'demRateApclDds', width: '50' },
      { column: 'dem20Rate', width: '50' },
      { column: 'dem40Rate', width: '50' },
      { column: 'dem45Rate', width: '50' },
      { column: 'demRateApclDds2', width: '50' },
      { column: 'dem20Rate2', width: '50' },
      { column: 'dem40Rate2', width: '50' },
      { column: 'dem45Rate2', width: '50' },
      { column: 'demRateApclDds3', width: '50' },
      { column: 'dem20Rate3', width: '50' },
      { column: 'dem40Rate3', width: '50' },
      { column: 'dem45Rate3', width: '50' },
      { column: 'demRateApclDds4', width: '50' },
      { column: 'dem20Rate4', width: '50' },
      { column: 'dem40Rate4', width: '50' },
      { column: 'dem45Rate4', width: '50' },
      { column: 'demRateApclDds5', width: '50' },
      { column: 'dem20Rate5', width: '50' },
      { column: 'dem40Rate5', width: '50' },
      { column: 'dem45Rate5', width: '50' }
    ]
  }
]

const opcDetLayer = [
  {
    name: 'Detention',
    direction: 'horizontal',
    width: '850',
    items: [
      { column: 'detCurCd', width: '50' },
      { column: 'detRateApclDds', width: '50' },
      { column: 'det20Rate', width: '50' },
      { column: 'det40Rate', width: '50' },
      { column: 'det45Rate', width: '50' },
      { column: 'detRateApclDds2', width: '50' },
      { column: 'det20Rate2', width: '50' },
      { column: 'det40Rate2', width: '50' },
      { column: 'det45Rate2', width: '50' },
      { column: 'detRateApclDds3', width: '50' },
      { column: 'det20Rate3', width: '50' },
      { column: 'det40Rate3', width: '50' },
      { column: 'det45Rate3', width: '50' },
      { column: 'detRateApclDds4', width: '50' },
      { column: 'det20Rate4', width: '50' },
      { column: 'det40Rate4', width: '50' },
      { column: 'det45Rate4', width: '50' },
      { column: 'detRateApclDds5', width: '50' },
      { column: 'det20Rate5', width: '50' },
      { column: 'det40Rate5', width: '50' },
      { column: 'det45Rate5', width: '50' }
    ]
  }
]

const opcDemDetLayer = [
  {
    name: 'DEM+DET',
    direction: 'horizontal',
    width: '850',
    items: [
      { column: 'demdetCurCd', width: '50' },
      { column: 'demdetRateApclDds', width: '50' },
      { column: 'demdet20Rate', width: '50' },
      { column: 'demdet40Rate', width: '50' },
      { column: 'demdet45Rate', width: '50' },
      { column: 'demdetRateApclDds2', width: '50' },
      { column: 'demdet20Rate2', width: '50' },
      { column: 'demdet40Rate2', width: '50' },
      { column: 'demdet45Rate2', width: '50' },
      { column: 'demdetRateApclDds3', width: '50' },
      { column: 'demdet20Rate3', width: '50' },
      { column: 'demdet40Rate3', width: '50' },
      { column: 'demdet45Rate3', width: '50' },
      { column: 'demdetRateApclDds4', width: '50' },
      { column: 'demdet20Rate4', width: '50' },
      { column: 'demdet40Rate4', width: '50' },
      { column: 'demdet45Rate4', width: '50' },
      { column: 'demdetRateApclDds5', width: '50' },
      { column: 'demdet20Rate5', width: '50' },
      { column: 'demdet40Rate5', width: '50' },
      { column: 'demdet45Rate5', width: '50' }
    ]
  }
]

const opcOfcLayer = [
  {
    name: 'OFC',
    direction: 'horizontal',
    width: '850',
    items: [
      { column: 'ofcCurCd', width: '50' },
      { column: 'ofcRateApclDds', width: '50' },
      { column: 'ofc20Rate', width: '50' },
      { column: 'ofc40Rate', width: '50' },
      { column: 'ofc45Rate', width: '50' },
      { column: 'ofcRateApclDds2', width: '50' },
      { column: 'ofc20Rate2', width: '50' },
      { column: 'ofc40Rate2', width: '50' },
      { column: 'ofc45Rate2', width: '50' },
      { column: 'ofcRateApclDds3', width: '50' },
      { column: 'ofc20Rate3', width: '50' },
      { column: 'ofc40Rate3', width: '50' },
      { column: 'ofc45Rate3', width: '50' },
      { column: 'ofcRateApclDds4', width: '50' },
      { column: 'ofc20Rate4', width: '50' },
      { column: 'ofc40Rate4', width: '50' },
      { column: 'ofc45Rate4', width: '50' },
      { column: 'ofcRateApclDds5', width: '50' },
      { column: 'ofc20Rate5', width: '50' },
      { column: 'ofc40Rate5', width: '50' },
      { column: 'ofc45Rate5', width: '50' }
    ]
  }
]

const matchColumns = {
  '01': opcDemColumns,
  '02': opcDetColumns,
  '03': opcDemDetColumns,
  '04': opcOfcColumns
}

const matchLayer = {
  '01': opcDemLayer,
  '02': opcDetLayer,
  '03': opcDemDetLayer,
  '04': opcOfcLayer
}

let layout = [
  ...defLayer
]

let columns = [
  ...defColumns
]

export default {
  name: 'DemDetTariff',
  directives: {
    print
  },
  components: {
    EBreadcrumbs,
    'e-auto-complete-place': () => import('@/components/common/EAutoCompletePlace')
  },
  computed: {
    ...rootComputed
  },
  data () {
    return {
      popupHeight: 600,
      gridHeight: 230,
      params: {
        ctrCd: '',
        portCd: '',
        eiCatCd: 'O'

        //test
        // ctrCd: 'HK',
        // portCd: 'HKG',
        // eiCatCd: 'O'
      },
      demDetTariffList: [],
      noticeFlag: false,

      checkDemCnt: 0,
      checkDetCnt: 0,
      checkDemDetCnt: 0,
      checkOfcCnt: 0
    }
  },
  created () {
    this.popupHeight = $('body').height() - 120
    this.gridHeight = this.popupHeight - 370
  },
  mounted () {
    //리얼그리드 셋팅
    provider = new LocalDataProvider(true)
    gridView = new GridView('tarrif-realgrid')
    gridView.setDataSource(provider)
    provider.setFields(fields)

    gridView.setColumns(columns)
    gridView.setColumnLayout(layout)
    gridView.setFooter({ visible: false })
    gridView.setRowIndicator({ visible: false })
    gridView.setStateBar({ visible: false })

    gridView.setEditOptions({
      editable: false,
      updatable: false
    })

    //체크바 없애기
    gridView.setCheckBar({
      visible: false
    })
  },
  methods: {
    changeDisplayColumn () {
      const frm = document.querySelector('#frm1')
      const elemsChk = frm.querySelectorAll('input[name="dt_chk"]:checked')
      // console.log(elemsChk.length)

      layout = [...defLayer]
      columns = [...defColumns]

      let opcLayout = []

      elemsChk.forEach(elem => {
        // layout = [...layout, ...matchLayer[elem.value]]
        opcLayout = [...opcLayout, ...matchLayer[elem.value]]
        columns = [...columns, ...matchColumns[elem.value]]
      })

      layout = [
        ...layout,
        {
          name: 'Over Period Charge',
          direction: 'horizontal',
          width: (elemsChk.length * 850) + '',
          items: [
            ...opcLayout
          ]
        }
      ]

      gridView.setColumns(columns)
      gridView.setColumnLayout(layout)
    },
    changeDisplayRow () {
      const frm = document.querySelector('#frm1')
      const elemsChk = frm.querySelectorAll('input[name="ct_chk"]')
      let hideRows = []

      provider.resetHiddenRows()

      elemsChk.forEach(elem => {
        if (elem.checked === false) {
          provider.getJsonRows(0, -1).forEach((row, index) => {
            if (elem.defaultValue === 'HC' && row.cd === 'HT') {
              hideRows.push(index)
            }
            if (elem.defaultValue === 'RF' && row.cd === 'RH') {
              hideRows.push(index)
            }
            if (row.cd === elem.defaultValue) {
              hideRows.push(index)
            }
          })
        }
      })

      provider.hideRows(hideRows)
    },
    changePod (vo) {
      this.params.ctrCd = vo.ctrCd
      this.params.portCd = vo.plcCd
      const frm = document.querySelector('#frm1')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    // '항구' check
    checkPod (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm1')
      }

      const elemPod = frm.querySelector('#podPod')
      let isOk = true

      if (this.$ekmtcCommon.isEmpty(this.params.ctrCd) || this.$ekmtcCommon.isEmpty(this.params.portCd)) {
        let msg = this.$t('msg.CSCT060G050.010') // 항구를 입력하세요.
        this.$ekmtcCommon.showErrorTooltip(elemPod, msg)
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemPod)
      }

      return isOk
    },
    async fninquiry () {
      const frm = document.querySelector('#frm1')
      let isOk = true

      if (!this.checkPod(frm)) {
        isOk = false
      }

      // 검색조건 '황목' 활성화 해제
      const elemsChk = frm.querySelectorAll('input[name="dt_chk"]')

      for (let item of elemsChk) {
        item.checked = false
      }

      this.changeDisplayColumn()

      if (isOk) {
        if (this.params.ctrCd === 'KR') {
          this.gridHeight = this.popupHeight - 380
          this.noticeFlag = true
        } else {
          this.gridHeight = this.popupHeight - 370
          this.noticeFlag = false
        }

        await demDetTariff.popupfreetimeinquiry(this.params)
          .then(response => {
            console.log(response)
            const resp = response.data

            this.demDetTariffList = []
            provider.setRows([])

            // 검색조건 '황목' 활성화/비황성화 체크
            this.checkDemCnt = 0
            this.checkDetCnt = 0
            this.checkDemDetCnt = 0
            this.checkOfcCnt = 0

            if (resp.demDetTariffList !== undefined && resp.demDetTariffList.length > 0) {
              const list = resp.demDetTariffList

              list.forEach((item) => {
                // item.dem20Dds = this.$ekmtcCommon.getOnlyNumber(item.dem20Dds, { isComma: true }).str
                item.dem20Dds = this.$ekmtcCommon.isNotEmpty(item.dem20Dds) && Number(item.dem20Dds) > 0 ? String(item.dem20Dds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem40Dds = this.$ekmtcCommon.isNotEmpty(item.dem40Dds) && Number(item.dem40Dds) > 0 ? String(item.dem40Dds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem45Dds = this.$ekmtcCommon.isNotEmpty(item.dem45Dds) && Number(item.dem45Dds) > 0 ? String(item.dem45Dds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det20Dds = this.$ekmtcCommon.isNotEmpty(item.det20Dds) && Number(item.det20Dds) > 0 ? String(item.det20Dds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det40Dds = this.$ekmtcCommon.isNotEmpty(item.det40Dds) && Number(item.det40Dds) > 0 ? String(item.det40Dds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det45Dds = this.$ekmtcCommon.isNotEmpty(item.det45Dds) && Number(item.det45Dds) > 0 ? String(item.det45Dds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet20Dds = this.$ekmtcCommon.isNotEmpty(item.demdet20Dds) && Number(item.demdet20Dds) > 0 ? String(item.demdet20Dds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet40Dds = this.$ekmtcCommon.isNotEmpty(item.demdet40Dds) && Number(item.demdet40Dds) > 0 ? String(item.demdet40Dds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet45Dds = this.$ekmtcCommon.isNotEmpty(item.demdet45Dds) && Number(item.demdet45Dds) > 0 ? String(item.demdet45Dds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc20Dds = this.$ekmtcCommon.isNotEmpty(item.ofc20Dds) && Number(item.ofc20Dds) > 0 ? String(item.ofc20Dds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc40Dds = this.$ekmtcCommon.isNotEmpty(item.ofc40Dds) && Number(item.ofc40Dds) > 0 ? String(item.ofc40Dds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc45Dds = this.$ekmtcCommon.isNotEmpty(item.ofc45Dds) && Number(item.ofc45Dds) > 0 ? String(item.ofc45Dds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''

                item.demRateApclDds = this.$ekmtcCommon.isNotEmpty(item.demRateApclDds) ? String(item.demRateApclDds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem20Rate = this.$ekmtcCommon.isNotEmpty(item.dem20Rate) && Number(item.dem20Rate) > 0 ? String(item.dem20Rate).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem40Rate = this.$ekmtcCommon.isNotEmpty(item.dem40Rate) && Number(item.dem40Rate) > 0 ? String(item.dem40Rate).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem45Rate = this.$ekmtcCommon.isNotEmpty(item.dem45Rate) && Number(item.dem45Rate) > 0 ? String(item.dem45Rate).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demRateApclDds2 = this.$ekmtcCommon.isNotEmpty(item.demRateApclDds2) ? String(item.demRateApclDds2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem20Rate2 = this.$ekmtcCommon.isNotEmpty(item.dem20Rate2) && Number(item.dem20Rate2) > 0 ? String(item.dem20Rate2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem40Rate2 = this.$ekmtcCommon.isNotEmpty(item.dem40Rate2) && Number(item.dem40Rate2) > 0 ? String(item.dem40Rate2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem45Rate2 = this.$ekmtcCommon.isNotEmpty(item.dem45Rate2) && Number(item.dem45Rate2) > 0 ? String(item.dem45Rate2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demRateApclDds3 = this.$ekmtcCommon.isNotEmpty(item.demRateApclDds3) ? String(item.demRateApclDds3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem20Rate3 = this.$ekmtcCommon.isNotEmpty(item.dem20Rate3) && Number(item.dem20Rate3) > 0 ? String(item.dem20Rate3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem40Rate3 = this.$ekmtcCommon.isNotEmpty(item.dem40Rate3) && Number(item.dem40Rate3) > 0 ? String(item.dem40Rate3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem45Rate3 = this.$ekmtcCommon.isNotEmpty(item.dem45Rate3) && Number(item.dem45Rate3) > 0 ? String(item.dem45Rate3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demRateApclDds4 = this.$ekmtcCommon.isNotEmpty(item.demRateApclDds4) ? String(item.demRateApclDds4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem20Rate4 = this.$ekmtcCommon.isNotEmpty(item.dem20Rate4) && Number(item.dem20Rate4) > 0 ? String(item.dem20Rate4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem40Rate4 = this.$ekmtcCommon.isNotEmpty(item.dem40Rate4) && Number(item.dem40Rate4) > 0 ? String(item.dem40Rate4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem45Rate4 = this.$ekmtcCommon.isNotEmpty(item.dem45Rate4) && Number(item.dem45Rate4) > 0 ? String(item.dem45Rate4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demRateApclDds5 = this.$ekmtcCommon.isNotEmpty(item.demRateApclDds5) ? String(item.demRateApclDds5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem20Rate5 = this.$ekmtcCommon.isNotEmpty(item.dem20Rate5) && Number(item.dem20Rate5) > 0 ? String(item.dem20Rate5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem40Rate5 = this.$ekmtcCommon.isNotEmpty(item.dem40Rate5) && Number(item.dem40Rate5) > 0 ? String(item.dem40Rate5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.dem45Rate5 = this.$ekmtcCommon.isNotEmpty(item.dem45Rate5) && Number(item.dem45Rate5) > 0 ? String(item.dem45Rate5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''

                item.detRateApclDds = this.$ekmtcCommon.isNotEmpty(item.detRateApclDds) ? String(item.detRateApclDds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det20Rate = this.$ekmtcCommon.isNotEmpty(item.det20Rate) && Number(item.det20Rate) > 0 ? String(item.det20Rate).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det40Rate = this.$ekmtcCommon.isNotEmpty(item.det40Rate) && Number(item.det40Rate) > 0 ? String(item.det40Rate).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det45Rate = this.$ekmtcCommon.isNotEmpty(item.det45Rate) && Number(item.det45Rate) > 0 ? String(item.det45Rate).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.detRateApclDds2 = this.$ekmtcCommon.isNotEmpty(item.detRateApclDds2) ? String(item.detRateApclDds2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det20Rate2 = this.$ekmtcCommon.isNotEmpty(item.det20Rate2) && Number(item.det20Rate2) > 0 ? String(item.det20Rate2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det40Rate2 = this.$ekmtcCommon.isNotEmpty(item.det40Rate2) && Number(item.det40Rate2) > 0 ? String(item.det40Rate2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det45Rate2 = this.$ekmtcCommon.isNotEmpty(item.det45Rate2) && Number(item.det45Rate2) > 0 ? String(item.det45Rate2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.detRateApclDds3 = this.$ekmtcCommon.isNotEmpty(item.detRateApclDds3) ? String(item.detRateApclDds3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det20Rate3 = this.$ekmtcCommon.isNotEmpty(item.det20Rate3) && Number(item.det20Rate3) > 0 ? String(item.det20Rate3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det40Rate3 = this.$ekmtcCommon.isNotEmpty(item.det40Rate3) && Number(item.det40Rate3) > 0 ? String(item.det40Rate3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det45Rate3 = this.$ekmtcCommon.isNotEmpty(item.det45Rate3) && Number(item.det45Rate3) > 0 ? String(item.det45Rate3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.detRateApclDds4 = this.$ekmtcCommon.isNotEmpty(item.detRateApclDds4) ? String(item.detRateApclDds4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det20Rate4 = this.$ekmtcCommon.isNotEmpty(item.det20Rate4) && Number(item.det20Rate4) > 0 ? String(item.det20Rate4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det40Rate4 = this.$ekmtcCommon.isNotEmpty(item.det40Rate4) && Number(item.det40Rate4) > 0 ? String(item.det40Rate4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det45Rate4 = this.$ekmtcCommon.isNotEmpty(item.det45Rate4) && Number(item.det45Rate4) > 0 ? String(item.det45Rate4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.detRateApclDds5 = this.$ekmtcCommon.isNotEmpty(item.detRateApclDds5) ? String(item.detRateApclDds5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det20Rate5 = this.$ekmtcCommon.isNotEmpty(item.det20Rate5) && Number(item.det20Rate5) > 0 ? String(item.det20Rate5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det40Rate5 = this.$ekmtcCommon.isNotEmpty(item.det40Rate5) && Number(item.det40Rate5) > 0 ? String(item.det40Rate5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.det45Rate5 = this.$ekmtcCommon.isNotEmpty(item.det45Rate5) && Number(item.det45Rate5) > 0 ? String(item.det45Rate5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''

                item.demdetRateApclDds = this.$ekmtcCommon.isNotEmpty(item.demdetRateApclDds) ? String(item.demdetRateApclDds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet20Rate = this.$ekmtcCommon.isNotEmpty(item.demdet20Rate) && Number(item.demdet20Rate) > 0 ? String(item.demdet20Rate).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet40Rate = this.$ekmtcCommon.isNotEmpty(item.demdet40Rate) && Number(item.demdet40Rate) > 0 ? String(item.demdet40Rate).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet45Rate = this.$ekmtcCommon.isNotEmpty(item.demdet45Rate) && Number(item.demdet45Rate) > 0 ? String(item.demdet45Rate).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdetRateApclDds2 = this.$ekmtcCommon.isNotEmpty(item.demdetRateApclDds2) ? String(item.demdetRateApclDds2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet20Rate2 = this.$ekmtcCommon.isNotEmpty(item.demdet20Rate2) && Number(item.demdet20Rate2) > 0 ? String(item.demdet20Rate2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet40Rate2 = this.$ekmtcCommon.isNotEmpty(item.demdet40Rate2) && Number(item.demdet40Rate2) > 0 ? String(item.demdet40Rate2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet45Rate2 = this.$ekmtcCommon.isNotEmpty(item.demdet45Rate2) && Number(item.demdet45Rate2) > 0 ? String(item.demdet45Rate2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdetRateApclDds3 = this.$ekmtcCommon.isNotEmpty(item.demdetRateApclDds3) ? String(item.demdetRateApclDds3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet20Rate3 = this.$ekmtcCommon.isNotEmpty(item.demdet20Rate3) && Number(item.demdet20Rate3) > 0 ? String(item.demdet20Rate3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet40Rate3 = this.$ekmtcCommon.isNotEmpty(item.demdet40Rate3) && Number(item.demdet40Rate3) > 0 ? String(item.demdet40Rate3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet45Rate3 = this.$ekmtcCommon.isNotEmpty(item.demdet45Rate3) && Number(item.demdet45Rate3) > 0 ? String(item.demdet45Rate3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdetRateApclDds4 = this.$ekmtcCommon.isNotEmpty(item.demdetRateApclDds4) ? String(item.demdetRateApclDds4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet20Rate4 = this.$ekmtcCommon.isNotEmpty(item.demdet20Rate4) && Number(item.demdet20Rate4) > 0 ? String(item.demdet20Rate4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet40Rate4 = this.$ekmtcCommon.isNotEmpty(item.demdet40Rate4) && Number(item.demdet40Rate4) > 0 ? String(item.demdet40Rate4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet45Rate4 = this.$ekmtcCommon.isNotEmpty(item.demdet45Rate4) && Number(item.demdet45Rate4) > 0 ? String(item.demdet45Rate4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdetRateApclDds5 = this.$ekmtcCommon.isNotEmpty(item.demdetRateApclDds5) ? String(item.demdetRateApclDds5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet20Rate5 = this.$ekmtcCommon.isNotEmpty(item.demdet20Rate5) && Number(item.demdet20Rate5) > 0 ? String(item.demdet20Rate5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet40Rate5 = this.$ekmtcCommon.isNotEmpty(item.demdet40Rate5) && Number(item.demdet40Rate5) > 0 ? String(item.demdet40Rate5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.demdet45Rate5 = this.$ekmtcCommon.isNotEmpty(item.demdet45Rate5) && Number(item.demdet45Rate5) > 0 ? String(item.demdet45Rate5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''

                item.ofcRateApclDds = this.$ekmtcCommon.isNotEmpty(item.ofcRateApclDds) ? String(item.ofcRateApclDds).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc20Rate = this.$ekmtcCommon.isNotEmpty(item.ofc20Rate) && Number(item.ofc20Rate) > 0 ? String(item.ofc20Rate).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc40Rate = this.$ekmtcCommon.isNotEmpty(item.ofc40Rate) && Number(item.ofc40Rate) > 0 ? String(item.ofc40Rate).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc45Rate = this.$ekmtcCommon.isNotEmpty(item.ofc45Rate) && Number(item.ofc45Rate) > 0 ? String(item.ofc45Rate).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofcRateApclDds2 = this.$ekmtcCommon.isNotEmpty(item.ofcRateApclDds2) ? String(item.ofcRateApclDds2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc20Rate2 = this.$ekmtcCommon.isNotEmpty(item.ofc20Rate2) && Number(item.ofc20Rate2) > 0 ? String(item.ofc20Rate2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc40Rate2 = this.$ekmtcCommon.isNotEmpty(item.ofc40Rate2) && Number(item.ofc40Rate2) > 0 ? String(item.ofc40Rate2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc45Rate2 = this.$ekmtcCommon.isNotEmpty(item.ofc45Rate2) && Number(item.ofc45Rate2) > 0 ? String(item.ofc45Rate2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofcRateApclDds3 = this.$ekmtcCommon.isNotEmpty(item.ofcRateApclDds3) ? String(item.ofcRateApclDds3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc20Rate3 = this.$ekmtcCommon.isNotEmpty(item.ofc20Rate3) && Number(item.ofc20Rate3) > 0 ? String(item.ofc20Rate3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc40Rate3 = this.$ekmtcCommon.isNotEmpty(item.ofc40Rate3) && Number(item.ofc40Rate3) > 0 ? String(item.ofc40Rate3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc45Rate3 = this.$ekmtcCommon.isNotEmpty(item.ofc45Rate3) && Number(item.ofc45Rate3) > 0 ? String(item.ofc45Rate3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofcRateApclDds4 = this.$ekmtcCommon.isNotEmpty(item.ofcRateApclDds4) ? String(item.ofcRateApclDds4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc20Rate4 = this.$ekmtcCommon.isNotEmpty(item.ofc20Rate4) && Number(item.ofc20Rate4) > 0 ? String(item.ofc20Rate4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc40Rate4 = this.$ekmtcCommon.isNotEmpty(item.ofc40Rate4) && Number(item.ofc40Rate4) > 0 ? String(item.ofc40Rate4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc45Rate4 = this.$ekmtcCommon.isNotEmpty(item.ofc45Rate4) && Number(item.ofc45Rate4) > 0 ? String(item.ofc45Rate4).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofcRateApclDds5 = this.$ekmtcCommon.isNotEmpty(item.ofcRateApclDds5) ? String(item.ofcRateApclDds5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc20Rate5 = this.$ekmtcCommon.isNotEmpty(item.ofc20Rate5) && Number(item.ofc20Rate5) > 0 ? String(item.ofc20Rate5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc40Rate5 = this.$ekmtcCommon.isNotEmpty(item.ofc40Rate5) && Number(item.ofc40Rate5) > 0 ? String(item.ofc40Rate5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                item.ofc45Rate5 = this.$ekmtcCommon.isNotEmpty(item.ofc45Rate5) && Number(item.ofc45Rate5) > 0 ? String(item.ofc45Rate5).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''

                if (this.$ekmtcCommon.isNotEmpty(item.dem20Dds) && this.$ekmtcCommon.isNotEmpty(item.dem40Dds) && this.$ekmtcCommon.isNotEmpty(item.dem45Dds)) {
                  this.checkDemCnt += Number(item.dem20Dds)
                  this.checkDemCnt += Number(item.dem40Dds)
                  this.checkDemCnt += Number(item.dem45Dds)
                }

                if (this.$ekmtcCommon.isNotEmpty(item.det20Dds) && this.$ekmtcCommon.isNotEmpty(item.det40Dds) && this.$ekmtcCommon.isNotEmpty(item.det45Dds)) {
                  this.checkDetCnt += Number(item.det20Dds)
                  this.checkDetCnt += Number(item.det40Dds)
                  this.checkDetCnt += Number(item.det45Dds)
                }

                if (this.$ekmtcCommon.isNotEmpty(item.demdet20Dds) && this.$ekmtcCommon.isNotEmpty(item.demdet40Dds) && this.$ekmtcCommon.isNotEmpty(item.demdet45Dds)) {
                  this.checkDemDetCnt += Number(item.demdet20Dds)
                  this.checkDemDetCnt += Number(item.demdet40Dds)
                  this.checkDemDetCnt += Number(item.demdet45Dds)
                }

                if (this.$ekmtcCommon.isNotEmpty(item.ofc20Dds) && this.$ekmtcCommon.isNotEmpty(item.ofc40Dds) && this.$ekmtcCommon.isNotEmpty(item.ofc45Dds)) {
                  this.checkOfcCnt += Number(item.ofc20Dds)
                  this.checkOfcCnt += Number(item.ofc40Dds)
                  this.checkOfcCnt += Number(item.ofc45Dds)
                }

                this.demDetTariffList.push(item)
              })

              // this.demDetTariffList = resp.demDetTariffList
              //그리드 데이터 셋팅
              provider.setRows(this.demDetTariffList)

              this.changeDisplayRow()
            }
          })
      }
    }
  }
}
</script>
